import Baobab from "baobab";
var monkeys = {
	RoomInfo:Baobab.monkey({
		cursors:{
			RoomInfo : ["model","RoomInfo"],
		},
		get: (data)=>{
			const res = {};
			data.RoomInfo.forEach(p=>{
				res[p.id] = p;
			})
			return res;
		}
	}),
	SmartRoomController:Baobab.monkey({
		cursors:{
			SmartRoomController : ["model","SmartRoomController"],
		},
		get: (data)=>{
			const res = {};
			data.SmartRoomController.forEach(p=>{
				res[p.id] = p;
			})
			return res;
		}
	}),
	Reservation:Baobab.monkey({
		cursors:{
			Reservation : ["model","Reservation"],
		},
		get: (data)=>{
			const res = {};
			data.Reservation.forEach(p=>{
				res[p.id] = p;
			})
			return res;
		}
	}),
	PosItem:Baobab.monkey({
		cursors:{
			PosItem : ["model","PosItem"],
		},
		get: (data)=>{
			const res = {};
			data.PosItem.forEach(p=>{
				res[p.id] = p;
			})
			return res;
		}
	}),
	Tax:Baobab.monkey({
		cursors:{
			Tax : ["model","Tax"],
		},
		get: (data)=>{
			const res = {};
			data.Tax.forEach(p=>{
				res[p.id] = p;
			})
			return res;
		}
	}),
}

export default monkeys;


