import React from 'react';
import baobabTree from '../data/state';

const PermissionCheck = (props) => {
    const { name = 'permissionName' } = props;
    const permissions = baobabTree.root.select(['state', 'userPermissions']).get()
    if(permissions?.features?.includes(name)){
         return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return null;
    }
};

export default PermissionCheck;