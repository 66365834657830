import React from 'react'
import PropTypes from 'prop-types'

import TrackKeys from '.'

const TrackKey = ({ track, toggleOpen, clickTrackButton, trackButtonIcon }) => {
  const { title, tracks, isOpen, hasButton, sideComponent, trackButtonIconOverride } = track
  const isExpandable = isOpen !== undefined

 // console.log();

  const buildSideComponent = () => {
    if (sideComponent) {
      return React.cloneElement(sideComponent)
    }
    if (hasButton && clickTrackButton) {
      const handleClick = () => clickTrackButton(track)
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <button className="rt-track-key__side-button" onClick={handleClick} type="button" >{trackButtonIconOverride ? trackButtonIconOverride : trackButtonIcon}</button>
    }

    return null
  }

  return (
    <li className="rt-track-key">
      <div className="rt-track-key__entry">
        {isExpandable && (
          <button
            title="Expand track"
            className={`rt-track-key__toggle ${isOpen ? 'rt-track-key__toggle--close' : 'rt-track-key__toggle--open'}`}
            onClick={() => toggleOpen(track)}
            type="button"
          >
            {isOpen ? 'Close' : 'Open'}
          </button>
        )}
        <div onClick={()=>{
          document.getElementById(`${track.id}`).style.display = "none"
        }} id={track.id} className="bg-dark text-center text-white rounded" style={{opacity:"0.9", fontSize:"12px", display:"none",position:"absolute", width:"200px", zIndex:2}}>{title}</div>
        <span onClick={()=>{
          document.getElementById(`${track.id}`).style.display = "block"
        }} className="rt-track-key__title" title={""}>{title}</span>
        {buildSideComponent()}
      </div>
      {isOpen && tracks && tracks.length > 0 && <TrackKeys tracks={tracks} toggleOpen={toggleOpen} />}
    </li>
  )
}

TrackKey.propTypes = {
  track: PropTypes.shape({
    title: PropTypes.element,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    isOpen: PropTypes.bool,
    hasButton: PropTypes.bool,
    sideComponent: PropTypes.element,
  }),
  toggleOpen: PropTypes.func,
  clickTrackButton: PropTypes.func,
  trackButtonIcon: PropTypes.element
}

TrackKey.defaultProps = {
  clickTrackButton: undefined,
  trackButtonIcon: null
}

export default TrackKey
