import React from 'react';
import baobabTree from '@data/state';

const isFeatureEnabled = (featureFlag) => {
    const fflags = baobabTree.root.select(['model', 'FeatureFlag']).get();
    if (fflags) {
        const fflgasArray = fflags.filter((f) => f.isEnabled).map((f) => f.name);
        return fflgasArray.includes(featureFlag);
    } else {
        return false;
    }
};

const FeatureFlag = (props) => {
    const { flag = 'someFeatureName' } = props;
    if (isFeatureEnabled(flag)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return null;
    }
};

export default FeatureFlag;
export { isFeatureEnabled };